

.App {
    font-family: sans-serif;
    text-align: center;
    height: 500rem;
    overflow: scroll;
  }
  
  .header-background {
    background: url(./images/meWallPaper.jpg);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    top: 0;
    
    
  }
  html {
    scroll-behavior: smooth;
  }
  .info-container {
    position: relative;
    background-color: transparent;
  }

  .no-bullets{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }

  .ability-title {
    display: inline-block;
    width: 60%;
    color: black;
  }

  .ability-score {
    float: right;
  }

  .icon-email {
    background: url("./images/gmailLogo.png");
    display: inline-block;
    height: 32px;
    width: 32px;
  }





